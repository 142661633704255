import styles from "./ContactUsLanding.module.scss";
import cn from "classnames";

import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useState, ReactNode } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";

import { api } from "pages/_app";
import CustomCheckbox from "components/shared/CustomCheckbox/CustomCheckbox";
import Input from "components/shared/Input/Input";
import { Button } from "components/shared/Button/Button";

import IconChat from "./icons/chat.svg";
import IconClock from "./icons/clock.svg";
import IconSettings from "./icons/settings.svg";
import IconSpark from "./icons/sparkles.svg";

interface Idata {
  type: string;
  recaptcha_token: string;
  utm_source?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  form: {
    name: string;
    email: string;
    telegram: string;
  };
}

export interface IPropertiesList {
  icon: ReactNode;
  title: string;
}

interface IFormProps {
  size: "xl" | "l";
  modal?: boolean;
  onSubmit?: () => void;
}

const defaultPropertiesList: IPropertiesList[] = [
  {
    icon: <IconSpark />,
    title: "Get the best solution for your business",
  },
  {
    icon: <IconClock />,
    title: "Reduce your commission to as low as 0-4%",
  },
  {
    icon: <IconChat />,
    title: "Resolve any issue with your own manager",
  },
  {
    icon: <IconSettings />,
    title: "Streamline integration with our dedicated support",
  },
];

const CheckboxLabel = () => {
  const { t } = useTranslation(["common"]);

  return (
    <p>
      {t("common:form.I have read and agree to the")}{" "}
      <Link href="/privacy" target="_blank">
        {t("common:form.Privacy Policy")}
      </Link>
    </p>
  );
};

const ContactUsLanding: React.FC<IFormProps> = ({ size, modal, onSubmit }) => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telegram, setTelegram] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [nameError, setNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [telegramError, setTelegramError] = useState<string>("");
  const [checkedError, setCheckedError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useMutation((data: Idata) => api.post("v1/form/save", data), {
    onSuccess: () => {
      setName("");
      setEmail("");
      setTelegram("");
      setIsChecked(false);
      setLoading(false);
      onSubmit && onSubmit();

      window?.dataLayer?.push({ event: "landing_submit_form" });

      enqueueSnackbar(t("form.Message sent"), {
        variant: "success",
      });
    },
    onError: (error: any) => {
      enqueueSnackbar(getErrorText(error?.response?.data?.errors), {
        variant: "error",
      });

      setLoading(false);
    },
  });

  // get error text from Form payload, without handling refresh token error
  const getErrorText = (errorObject): null | string => {
    if (typeof errorObject === "object") {
      const formErrorField = Object.values(errorObject?.form).find((value) => Array.isArray(value));

      if (formErrorField && Array.isArray(formErrorField[0])) {
        return formErrorField[0][0];
      }
    }

    return null;
  };

  const validateEmail = (email: string): RegExpMatchArray | null => {
    return String(email)
      .toLowerCase()
      .match(
        /^("[^"]+"|[a-z0-9а-яА-ЯёЁ+_]+([-.][a-z0-9а-яА-ЯёЁ+_]+)*)@[a-z0-9а-яА-ЯёЁ]+([-][a-z0-9а-яА-ЯёЁ]+)*(\.[a-z0-9а-яА-ЯёЁ-]+)*(\.[a-zа-яА-ЯёЁ]{2,11})$/i,
      );
  };

  const validateTelegram = (telegram: string): RegExpMatchArray | null => {
    return String(telegram).match(/^[a-z0-9_@]+$/i);
  };

  const checkFields = (): boolean => {
    let ok = true;

    if (!name) {
      setNameError(" ");
      ok = false;
    } else if (!email) {
      setEmailError(" ");
      ok = false;
    } else if (name.length < 2) {
      setNameError(t("form.The name must be at least"));
      ok = false;
    } else if (telegram.length < 5 && telegram.length) {
      setTelegramError(t("form.The name must be at least 5 characters"));
      ok = false;
    } else if (name.length > 60) {
      setNameError(t("form.The name must not be greater than"));
      ok = false;
    } else if (!validateEmail(email)) {
      setEmailError(t("form.The email must be a valid email address"));
      ok = false;
    } else if (!validateTelegram(telegram) && telegram.length) {
      if (telegram) {
        setTelegramError(t("form.The telegram name must be contain only English characters"));
        ok = false;
      } else {
        setTelegramError("");
        ok = true;
      }
    } else if (!isChecked) {
      setCheckedError(true);
      ok = false;
    }

    return ok;
  };

  const contactUs = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (!checkFields()) return;

    if (!executeRecaptcha) return;

    executeRecaptcha("submit").then((gReCaptchaToken) => {
      setLoading(true);

      mutation.mutate({
        type: "contact",
        recaptcha_token: gReCaptchaToken,
        utm_source: Cookies.get("utm_source"),
        utm_content: Cookies.get("utm_content"),
        utm_medium: Cookies.get("utm_medium"),
        utm_campaign: Cookies.get("utm_campaign"),
        utm_term: Cookies.get("utm_term"),
        form: {
          name,
          email,
          telegram: telegram.startsWith("@") ? telegram : `@${telegram}`,
        },
      });
    });
  };

  return (
    <section
      id="leave-contact"
      className={cn(styles.contactForm, { [styles.without_padding]: modal })}
    >
      <div className={styles.title}>
        <h2>{t("Let’s keep in touch!")}</h2>
        <ul>
          {defaultPropertiesList?.map((item) => (
            <li key={item.title}>
              {item.icon}
              <p>{t(item.title)}</p>
            </li>
          ))}
        </ul>
      </div>
      <form className={styles.form}>
        <div className={styles.inputs}>
          <Input
            placeholder={t("form.Full name*")}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
              setNameError("");
            }}
            error={nameError}
            maxLength={255}
            disabled={loading}
          />
          <Input
            placeholder={t("form.Email*")}
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value.trim());
              setEmailError("");
            }}
            error={emailError}
            maxLength={255}
            disabled={loading}
          />
          <Input
            placeholder="Telegram/WhatsApp/Skype"
            value={telegram}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTelegram(e.target.value.trim());
              setTelegramError("");
            }}
            disabled={loading}
            error={telegramError}
            maxLength={255}
          />
        </div>
        <div className={styles.form__button}>
          <CustomCheckbox
            label={<CheckboxLabel />}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            setCheckedError={setCheckedError}
            checkedError={checkedError}
            disabled={loading}
            modal={modal}
          />
          <div className="recaptcha-token" id="recaptcha-token" />
          <Button
            size={size}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => contactUs(e)}
            loading={loading}
          >
            {t("form.Send")}
          </Button>
        </div>
      </form>
    </section>
  );
};

export default ContactUsLanding;
